import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import env from '../../../../src/env';
import { useHistory } from 'react-router';
import {
  getQuoteBuilderURL,
  capitalizeStr,
  generateUniqueID,
} from '../../../helpers/common';
import ColumnHeadersTooltip from '../../../components/ColumnHeadersTooltip';
import TooltipItem from '../../../components/TooltipItem';

/**
 * graphQL query for all the data needed for quote items UI
 * Quote - quote based from the passed quote id in the URL
 * Bundles, ProductCategories - list all the product for the live search
 * QuoteItems - query all the quote items of the current quote based on the URL
 **/
const DATA = gql`
  query Data($QuoteId: ID) {
    Quote(QuoteId: $QuoteId) {
      QuoteId
      HubSpotDealId
      Description
      HubSpotDealValue
      HubSpotDealOwner
      PrimaryContact
      QuoteTypeId1
      QuoteType1 {
        QuoteTypeId
        Name
        QantasPointsApplicable
      }
      QuoteTypeId2
      QuoteType2 {
        QuoteTypeId
        Name
        QantasPointsApplicable
      }
      Quote1DiscountMonthly
      Quote1DiscountTypeMonthly
      Quote1DiscountHardware
      Quote1DiscountTypeHardware
      Quote1DiscountSetup
      Quote1DiscountTypeSetup
      Quote2DiscountMonthly
      Quote2DiscountTypeMonthly
      Quote2DiscountHardware
      Quote2DiscountTypeHardware
      Quote2DiscountSetup
      Quote2DiscountTypeSetup
      QuoteType1QantasBonus
      QuoteType2QantasBonus
      WorkflowStep
      AcceptedQuote
      Status
      SummariseAllHardwareItemsQuote1
      SummariseAllHardwareItemsQuote2
    }

    ProposalInserts(QuoteId: $QuoteId) {
      InsertId
    }

    Bundles {
      BundleId
      Name
      Description
      BundleItems {
        BundleItemId
        ProductId
        Quantity
        isLinkedToGroup
        Product {
          ProductId
          Name
          Description
          ProductPrices {
            ProductId
            ProductPriceId
            Description
            QuoteTypeId
            PriceType
            Price
            QantasPointsApplicable
            isDisplayedPrice
            DisplayProduct
            IncludePriceInTotal
          }
          ProductDocuments {
            DocId
            Name
            FilePath
          }

          ProductTemplates {
            ProductTemplateId
            ProductTemplateName
            ProductTemplateTOCLine
          }
        }
      }
    }

    ProductCategories {
      CatId
      Name
      Products {
        ProductId
        Name
        Description
        ProductPrices {
          ProductId
          ProductPriceId
          QuoteTypeId
          PriceType
          Price
          QantasPointsApplicable
          Description
          isDisplayedPrice
          DisplayProduct
          IncludePriceInTotal
        }
        ProductDocuments {
          DocId
          Name
          FilePath
        }
        ProductTemplates {
          ProductTemplateId
          ProductTemplateName
          ProductTemplateTOCLine
        }
        ProductRates {
          RateId
          RateType
          RateUnits
          Rate
          QuoteTypeId
          QuoteType {
            QuoteTypeId
            Name
          }
        }
      }
    }

    QuoteItems(QuoteId: $QuoteId) {
      LineId
      QuoteId
      ProductId
      Qty
      ItemGroupId
      Description
      PriceType
      Price0
      Price1
      Price2
      ComparisonLineItems
      ComparisonPrice
      SiteTag
      DocId
      TemplateId
      Order
      Price1QantasPointsApplicable
      Price2QantasPointsApplicable
      Modification
      ShowPriceQuote1
      ShowPriceQuote2
      DisplayProductQuote1
      DisplayProductQuote2
      IncludePriceInTotalQuote1
      IncludePriceInTotalQuote2
      Product {
        Name
        XeroTotalCostPool
        XeroQuantityOnHand
        ZapierQuoted
        ZapierOrdered
        ProductDocuments {
          DocId
          Name
          FilePath
        }
        ProductTemplates {
          ProductTemplateId
          ProductTemplateName
          ProductTemplateTOCLine
        }
        ProductRates {
          RateId
          RateType
          RateUnits
          Rate
          QuoteTypeId
          QuoteType {
            QuoteTypeId
            Name
          }
        }
      }
    }
  }
`;

const UPDATE_QUOTE_ITEM = gql`
  mutation updateQuoteItem(
    $LineId: ID
    $PriceType: PriceType
    $Price1: Float
    $Price2: Float
    $Qty: Int
    $ItemGroupId: String
    $ComparisonLineItems: String
    $ComparisonPrice: Float
    $SiteTag: String
    $DocId: Int
    $TemplateId: Int
    $Order: Int
    $Modification: Int
    $ShowPriceQuote1: Int
    $ShowPriceQuote2: Int
    $DisplayProductQuote1: Int
    $DisplayProductQuote2: Int
    $IncludePriceInTotalQuote1: Int
    $IncludePriceInTotalQuote2: Int
  ) {
    updateQuoteItem(
      LineId: $LineId
      PriceType: $PriceType
      Price1: $Price1
      Price2: $Price2
      Qty: $Qty
      ItemGroupId: $ItemGroupId
      ComparisonLineItems: $ComparisonLineItems
      ComparisonPrice: $ComparisonPrice
      SiteTag: $SiteTag
      DocId: $DocId
      TemplateId: $TemplateId
      Order: $Order
      Modification: $Modification
      ShowPriceQuote1: $ShowPriceQuote1
      ShowPriceQuote2: $ShowPriceQuote2
      DisplayProductQuote1: $DisplayProductQuote1
      DisplayProductQuote2: $DisplayProductQuote2
      IncludePriceInTotalQuote1: $IncludePriceInTotalQuote1
      IncludePriceInTotalQuote2: $IncludePriceInTotalQuote2
    ) {
      LineId
      QuoteId
      ProductId
      Qty
      ItemGroupId
      PriceType
      Price0
      Price1
      Price2
      ComparisonLineItems
      ComparisonPrice
      SiteTag
      DocId
      TemplateId
      Order
      Price1QantasPointsApplicable
      Price2QantasPointsApplicable
      Modification
      ShowPriceQuote1
      ShowPriceQuote2
      DisplayProductQuote1
      DisplayProductQuote2
      IncludePriceInTotalQuote1
      IncludePriceInTotalQuote2
      Product {
        Name
        ZapierQuoted
        ZapierOrdered
        ProductDocuments {
          DocId
          Name
          FilePath
        }
        ProductTemplates {
          ProductTemplateId
          ProductTemplateName
          ProductTemplateContent
          ProductTemplateTOCLine
        }
        ProductRates {
          RateId
          RateType
          RateUnits
          Rate
          QuoteTypeId
          QuoteType {
            QuoteTypeId
            Name
          }
        }
      }
    }
  }
`;

const DELETE_QUOTE_ITEM = gql`
  mutation deleteQuoteItem($LineId: ID!) {
    deleteQuoteItem(LineId: $LineId) {
      LineId
      QuoteId
      ProductId
      Qty
      ItemGroupId
      PriceType
      Price0
      Price1
      Price2
      ComparisonLineItems
      ComparisonPrice
      SiteTag
      DocId
      Order
      Product {
        Name
      }
    }
  }
`;

/**
 * graphQL query for creating/adding a quote items to the database
 * based on the current quote and product prices selected on the products live search
 **/
const CREATE_QUOTE_ITEMS = gql`
  mutation createQuoteItems($QuoteItemsList: [QuoteItemsList]) {
    createQuoteItems(input: $QuoteItemsList) {
      LineId
      QuoteId
      ProductId
      Qty
      ItemGroupId
      Description
      PriceType
      Price0
      Price1
      Price2
      ComparisonLineItems
      ComparisonPrice
      SiteTag
      DocId
      Order
      Price1QantasPointsApplicable
      Price2QantasPointsApplicable
      Modification
      ShowPriceQuote1
      ShowPriceQuote2
      DisplayProductQuote1
      DisplayProductQuote2
      IncludePriceInTotalQuote1
      IncludePriceInTotalQuote2
      Product {
        Name
        ZapierQuoted
        ZapierOrdered
        ProductDocuments {
          DocId
          Name
          FilePath
        }
        ProductTemplates {
          ProductTemplateId
          ProductTemplateName
          ProductTemplateContent
          ProductTemplateTOCLine
        }
        ProductRates {
          RateId
          RateType
          RateUnits
          Rate
          QuoteTypeId
          QuoteType {
            QuoteTypeId
            Name
          }
        }
      }
    }
  }
`;

const UPDATE_QUOTE_ITEMS = gql`
  mutation updateQuoteItems($QuoteItemsList: [QuoteItemsList]) {
    updateQuoteItems(input: $QuoteItemsList) {
      LineId
      QuoteId
      ProductId
      Qty
      ItemGroupId
      PriceType
      Price0
      Price1
      Price2
      ComparisonLineItems
      ComparisonPrice
      SiteTag
      DocId
      TemplateId
      Order
      Price1QantasPointsApplicable
      Price2QantasPointsApplicable
      Modification
      ShowPriceQuote1
      ShowPriceQuote2
      DisplayProductQuote1
      DisplayProductQuote2
      IncludePriceInTotalQuote1
      IncludePriceInTotalQuote2
      Product {
        Name
        ZapierQuoted
        ZapierOrdered
        ProductDocuments {
          DocId
          Name
          FilePath
        }
        ProductTemplates {
          ProductTemplateId
          ProductTemplateName
          ProductTemplateContent
          ProductTemplateTOCLine
        }
        ProductRates {
          RateId
          RateType
          RateUnits
          Rate
          QuoteTypeId
          QuoteType {
            QuoteTypeId
            Name
          }
        }
      }
    }
  }
`;

const CREATE_QUOTE_RATES = gql`
  mutation createQuoteRates($QuoteRatesList: [QuoteRatesList]) {
    createQuoteRates(input: $QuoteRatesList) {
      QuoteRateId
      QuoteId
      RateType
      RateUnits
      Quote1Rate
      Quote2Rate
      CompareDesc
      ComparePrice
      CompareQty
      Order
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      QuoteId
      CompanyName
      Quote1DiscountMonthly
      Quote1DiscountTypeMonthly
      Quote1DiscountHardware
      Quote1DiscountTypeHardware
      Quote1DiscountSetup
      Quote1DiscountTypeSetup
      Quote2DiscountMonthly
      Quote2DiscountTypeMonthly
      Quote2DiscountHardware
      Quote2DiscountTypeHardware
      Quote2DiscountSetup
      Quote2DiscountTypeSetup
      QuoteType1QantasBonus
      QuoteType2QantasBonus
      WorkflowStep
      AcceptedQuote
      Token
    }
  }
`;

const GENERATE_STATIC_PROPOSAL = gql`
  mutation generateStaticProposalHTML($QuoteId: Int, $Token: String) {
    generateStaticProposalHTML(QuoteId: $QuoteId, Token: $Token) {
      message
      QuoteVersion {
        QuoteId
        VersionId
        Timestamp
        Token
        File
      }
    }
  }
`;

const QUOTE_ITEMS_COLUMN_HEADERS = [
  {
    id: 1,
    field: 'Name',
    label: 'Item',
    description: 'The name of the Product',
    width: '140px',
  },
  {
    id: 2,
    field: 'Qty',
    label: 'Qty',
    description:
      'The number of units of this line item relative to the Group ID',
    width: '90px',
  },
  {
    id: 12,
    field: 'ItemGroupId',
    label: 'Group ID',
    description: 'Unique ID group of quote items in relation to qty',
    width: '120px',
  },
  {
    id: 3,
    field: 'ProductId',
    label: 'Product Code',
    description: 'The unique identifier for this product',
    width: '120px',
  },
  {
    id: 11,
    field: 'Modification',
    label: 'Modification',
    description:
      'Will this line item be a modification to an existing service?',
    width: '80px',
  },
  {
    id: 10,
    field: 'Price0',
    label: 'Cost Price',
    description:
      'The worst-case cost price of this line item.  Note that some items may have their true cost price split across monthly and up-front prices.',
    width: '150px',
  },
  {
    id: 4,
    field: 'Price1',
    label: 'Quote 1 Price',
    description:
      'The price for this line item, of this cost type, for the first quote type to be included in the proposal',
    quoteType: true,
    width: '150px',
  },
  {
    id: 5,
    field: 'Price2',
    label: 'Quote 2 Price',
    description:
      'The price for this line item, of this cost type, for the second quote type to be included in the proposal',
    quoteType: true,
    width: '150px',
  },
  {
    id: 6,
    field: 'ComparisonLineItems',
    label: 'Comparison Line Item',
    description:
      "When comparing to a competitor's quote or bill, enter the equivalent line item to which this line should be compared",
    width: '160px',
  },
  {
    id: 7,
    field: 'ComparisonPrice',
    label: 'Comparison Price',
    description:
      "The competitor's prices for the comparison line item, to which this line should be compared",
    width: '100px',
  },
  {
    id: 8,
    field: 'Price',
    label: 'Site Tag',
    description:
      'If this quote includes multiple sites, enter the site for which this line should be categorised.  For example, if the customer has 10 handsets going to 1 site, and 10 to another, you would add 2 line items and assign each to the relevant site',
    width: '90px',
  },
  {
    id: 9,
    field: 'TemplateId',
    label: 'Proposal Template',
    description:
      'The template that will be inserted into the proposal for this product.  If more than one template is available for the product, you can select one here.',
    width: '180px',
  },
];

const QuoteBuilderItems = (props) => {
  props.activateAuthLayout();

  let history = useHistory();

  // initialize states
  const [isInit, setIsInit] = useState(false);
  const [quoteItemsData, setQuoteItemsData] = useState(null);
  const [stepError, setStepError] = useState(null);
  const PRICE_TYPES = ['monthly', 'hardware', 'setup']; // price type of products
  const QUOTE_TYPES_BADGE_CLASSES = {
    CAPEX: 'badge-primary',
    OPEX: 'badge-info',
    QANTAS: 'badge-dark',
    ENERDS: 'badge-danger',
  };
  const [groupOptions, setGroupOptions] = useState(null); // options/items on the live search
  const [quoteItems, setQuoteItems] = useState(null); // store the queried data here
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [proceed, setProceed] = useState(false);
  const [previous, setPrevious] = useState(false);
  const [isSetCreatedQuoteItems, setIsSetCreatedQuoteItems] = useState(false);
  const [quoteDiscounts, setQuoteDiscounts] = useState(null);
  const [qantasBonusPoints, setQantasBonusPoints] = useState({
    QuoteType1QantasBonus: 0,
    QuoteType2QantasBonus: 0,
  });
  const [proposalInserts, setProposalInserts] = useState([]);

  // initialize apollo graphql queries and mutations
  const { data, loading, refetch } = useQuery(DATA, {
    variables: { QuoteId: props.pathArg },
  });
  const [updateQuoteItem] = useMutation(UPDATE_QUOTE_ITEM);
  const [deleteQuoteItem] = useMutation(DELETE_QUOTE_ITEM);
  const [createQuoteItems, { data: createQuoteItemsData }] =
    useMutation(CREATE_QUOTE_ITEMS);
  const [updateQuoteItems] = useMutation(UPDATE_QUOTE_ITEMS);
  const [createQuoteRates, { data: createdQuoteRatesData }] =
    useMutation(CREATE_QUOTE_RATES);
  const [isCreatedQuoteRates, setIsCreatedQuoteRates] = useState(false);
  const [updateQuote, { data: updatedQuoteData }] = useMutation(UPDATE_QUOTE);
  const [isQuoteUpdated, setIsQuoteUpdated] = useState(false);
  const [disableQuote, setDisableQuote] = useState(false);
  const [SummariseAllHardwareItemsQuote1, setSummariseAllHardwareItemsQuote1] =
    useState(null);
  const [SummariseAllHardwareItemsQuote2, setSummariseAllHardwareItemsQuote2] =
    useState(null);
  const [
    generateStaticProposalHTML,
    { data: generatedStaticProposalHTMLData },
  ] = useMutation(GENERATE_STATIC_PROPOSAL);

  const [updateProposal, setUpdateProposal] = useState(false);

  /**
   * useEffect() - function equivalent to componentDidMount
   **/
  useEffect(() => {
    // to refetch/refresh quote items data
    if (data && quoteItemsData == null && isInit === false) {
      refetch();
      setIsInit(true);
    }

    if (
      data &&
      data.Quote &&
      data.Bundles &&
      data.ProductCategories &&
      groupOptions === null &&
      quoteItemsData == null &&
      isInit === true
    ) {
      // redirect to the current workflow step
      if (
        data.Quote &&
        data.Quote.AcceptedQuote &&
        data.Quote.WorkflowStep == 'CUSTOMER_ACCEPTED'
      ) {
        data.Quote &&
          data.Quote.WorkflowStep !== 'QUOTE_ITEMS' &&
          window.location.replace(
            getQuoteBuilderURL(
              data.Quote.WorkflowStep,
              data.Quote.QuoteId,
              data.Quote.HubSpotDealId
            )
          );
      }

      // if (data.Quote && data.Quote.AcceptedQuote) {
      //   if (
      //     [
      //       'QUOTE_SETUP',
      //       'QUOTE_ITEMS',
      //       'QUOTE_RATES',
      //       'CUSTOMISE_PROPOSAL',
      //       'SEND_PROPOSAL',
      //     ].includes(data.Quote.WorkflowStep)
      //   ) {
      //     const quote = {};
      //     quote['QuoteId'] = data.Quote.QuoteId;
      //     quote['WorkflowStep'] = 'CUSTOMISE_CAF';
      //     updateQuote({
      //       variables: {
      //         QuoteInput: quote,
      //       },
      //     });
      //     window.location.replace(
      //       getQuoteBuilderURL(
      //         'CUSTOMISE_CAF',
      //         data.Quote.QuoteId,
      //         data.Quote.HubSpotDealId
      //       )
      //     );
      //     return;
      //   } else {
      //     window.location.replace(
      //       getQuoteBuilderURL(
      //         data.Quote.WorkflowStep,
      //         data.Quote.QuoteId,
      //         data.Quote.HubSpotDealId
      //       )
      //     );
      //     return;
      //   }
      // }

      // set quote items data for refreshing the data
      setQuoteItemsData(data);

      // disable screen when status is accepted, declined or voided
      const status = data.Quote.Status;
      setDisableQuote(
        status === 'accepted' || status === 'declined' ? true : false
      );

      let tempQuoteItems = data.QuoteItems || [];
      let quoteLineItemsData = [];
      PRICE_TYPES.map((type) => {
        let quoteLineItems = tempQuoteItems.filter((item) => {
          return item.PriceType === type;
        });
        quoteLineItems.map((item) => {
          let duplicate = quoteLineItems
            .filter((iitem) => {
              return item.LineId !== iitem.LineId && item.Order === iitem.Order;
            })
            .map((iitem) => {
              iitem.Order = item.Order + 1;
              return iitem;
            });
          return { ...duplicate };
        });
        quoteLineItemsData.push(...quoteLineItems);
      });

      setQuoteItems(quoteLineItemsData);

      setProposalInserts(data.ProposalInserts);

      let groupOptions = [];

      // add bundles data to the live search
      groupOptions.push({
        label: 'Bundles',
        options: data.Bundles.map((bundle) => ({
          value: bundle.BundleId,
          label: bundle.Name,
          prices: [],
          items: bundle.BundleItems,
        })),
      });

      // add product categories with products data to the live search
      data.ProductCategories.map((category) =>
        groupOptions.push({
          label: category.Name,
          options: category.Products.map((product) => ({
            label: product.Name,
            value: product.ProductId,
            prices: product.ProductPrices,
            docs: product.ProductDocuments,
            templates: product.ProductTemplates,
          })),
        })
      );
      setGroupOptions(groupOptions);
      setQuoteDiscounts({
        Quote1DiscountMonthly: parseFloat(data.Quote.Quote1DiscountMonthly),
        Quote1DiscountTypeMonthly: data.Quote.Quote1DiscountTypeMonthly,
        Quote1DiscountHardware: parseFloat(data.Quote.Quote1DiscountHardware),
        Quote1DiscountTypeHardware: data.Quote.Quote1DiscountTypeHardware,
        Quote1DiscountSetup: parseFloat(data.Quote.Quote1DiscountSetup),
        Quote1DiscountTypeSetup: data.Quote.Quote1DiscountTypeSetup,
        Quote2DiscountMonthly: parseFloat(data.Quote.Quote2DiscountMonthly),
        Quote2DiscountTypeMonthly: data.Quote.Quote2DiscountTypeMonthly,
        Quote2DiscountHardware: parseFloat(data.Quote.Quote2DiscountHardware),
        Quote2DiscountTypeHardware: data.Quote.Quote2DiscountTypeHardware,
        Quote2DiscountSetup: parseFloat(data.Quote.Quote2DiscountSetup),
        Quote2DiscountTypeSetup: data.Quote.Quote2DiscountTypeSetup,
      });
      setQantasBonusPoints({
        QuoteType1QantasBonus: parseFloat(data.Quote.QuoteType1QantasBonus),
        QuoteType2QantasBonus: parseFloat(data.Quote.QuoteType2QantasBonus),
      });

      // add all product prices to productPrices state
      const productPrices = [];
      data.ProductCategories.forEach((category) => {
        category.Products.forEach((product) => {
          product.ProductPrices.forEach((price) => {
            productPrices.push(price);
          });
        });
      });
    }

    // sync the updated quote items from DB to the quote items state
    if (data && data.QuoteItems !== quoteItems) {
      // setQuoteItems(data.QuoteItems);
    }
    if (
      createQuoteItemsData &&
      createQuoteItemsData.createQuoteItems !== quoteItems &&
      isSetCreatedQuoteItems === false
    ) {
      setQuoteItems(createQuoteItemsData.createQuoteItems);
      setIsSetCreatedQuoteItems(true);
    }
    if (proposalInserts.length) {
      if (
        createdQuoteRatesData &&
        generatedStaticProposalHTMLData &&
        isCreatedQuoteRates === false
      ) {
        window.location.replace(`/quote-rates/${props.pathArg}`);
        setIsCreatedQuoteRates(true);
      }
    } else {
      if (createdQuoteRatesData && isCreatedQuoteRates === false) {
        window.location.replace(`/quote-rates/${props.pathArg}`);
        setIsCreatedQuoteRates(true);
      }
    }

    if (previous === true && updatedQuoteData) {
      window.location.replace(
        `/deal/${data.Quote.HubSpotDealId}/${data.Quote.QuoteId}`
      );
      setPrevious(false);
    }
    if (updatedQuoteData && isQuoteUpdated === false) {
      setIsQuoteUpdated(true);
    }

    if (updateProposal && updatedQuoteData) {
      updateStaticProposalHTML();
      setUpdateProposal(false);
    }

    // clean up function - set quote items data and isInit to false to refetch data again
    return () => {
      if (data && quoteItemsData !== null) {
        setQuoteItemsData(null);
        setIsInit(false);
      }
    };
  }, [
    data,
    groupOptions,
    createQuoteItemsData,
    isSetCreatedQuoteItems,
    quoteItems,
    createdQuoteRatesData,
    isCreatedQuoteRates,
    props,
    PRICE_TYPES,
    isQuoteUpdated,
    setIsQuoteUpdated,
    updatedQuoteData,
    previous,
    history,
    quoteItemsData,
    isInit,
    refetch,
  ]);
  const onChangeQantasBonusPoints = (e, field) => {
    const quote = {};
    quote['QuoteId'] = data.Quote.QuoteId;
    quote['HubSpotDealId'] = data.Quote.HubSpotDealId;
    quote[field] = parseFloat(e.target.value ? e.target.value : 0);
    updateQuote({
      variables: {
        QuoteInput: quote,
      },
    });
    setQantasBonusPoints({
      ...qantasBonusPoints,
      [field]: quote[field],
    });
  };

  const onChangeQuoteDiscountTotal = (e, field) => {
    const quote = {};
    quote['QuoteId'] = data.Quote.QuoteId;
    quote['HubSpotDealId'] = data.Quote.HubSpotDealId;
   
   let value = e.value ? e.value : e.target.value;

    switch( field ) {
      case 'Quote1DiscountHardware':
      case 'Quote2DiscountHardware':
      case 'Quote1DiscountSetup':
      case 'Quote2DiscountSetup':
      case 'Quote1DiscountMonthly':
      case 'Quote2DiscountMonthly':
        quote[field] = parseFloat(value);
        break;
      default:
        quote[field] = e.value ? e.value : e.target.value;
        break;
    }
 
    updateQuote({
      variables: {
        QuoteInput: quote,
      },
    });
    setQuoteDiscounts({
      ...quoteDiscounts,
      [field]: quote[field],
    });
  };

  const calculateQuoteTotal = () => {
    let quoteTotals = {
      Quote1HardwareTotal: 0,
      Quote2HardwareTotal: 0,
      Quote1MonthlyTotal: 0,
      Quote2MonthlyTotal: 0,
      Quote1SetupTotal: 0,
      Quote2SetupTotal: 0,
    };
    quoteItems.forEach((item) => {
      let qty = (item && item.Qty && parseInt(item.Qty)) || 0;

      if (item.IncludePriceInTotalQuote1) {
        quoteTotals[`Quote1${capitalizeStr(item.PriceType)}Total`] +=
          parseFloat(item.Price1 ? item.Price1 * qty : 0);
      }

      if (item.IncludePriceInTotalQuote2) {
        quoteTotals[`Quote2${capitalizeStr(item.PriceType)}Total`] +=
          parseFloat(item.Price2 ? item.Price2 * qty : 0);
      }
    });
    // monthly
    quoteTotals.Quote1MonthlySubTotal = quoteTotals.Quote1MonthlyTotal;
    quoteTotals.Quote1MonthlyTotal = calculateDiscount(
      quoteDiscounts.Quote1DiscountTypeMonthly,
      quoteDiscounts.Quote1DiscountMonthly,
      quoteTotals.Quote1MonthlyTotal
    );
    quoteTotals.Quote2MonthlySubTotal = quoteTotals.Quote2MonthlyTotal;
    quoteTotals.Quote2MonthlyTotal = calculateDiscount(
      quoteDiscounts.Quote2DiscountTypeMonthly,
      quoteDiscounts.Quote2DiscountMonthly,
      quoteTotals.Quote2MonthlyTotal
    );

    // hardware
    quoteTotals.Quote1HardwareSubTotal = quoteTotals.Quote1HardwareTotal;
    quoteTotals.Quote1HardwareTotal = calculateDiscount(
      quoteDiscounts.Quote1DiscountTypeHardware,
      quoteDiscounts.Quote1DiscountHardware,
      quoteTotals.Quote1HardwareTotal
    );
    quoteTotals.Quote2HardwareSubTotal = quoteTotals.Quote2HardwareTotal;
    quoteTotals.Quote2HardwareTotal = calculateDiscount(
      quoteDiscounts.Quote2DiscountTypeHardware,
      quoteDiscounts.Quote2DiscountHardware,
      quoteTotals.Quote2HardwareTotal
    );

    // setup
    quoteTotals.Quote1SetupSubTotal = quoteTotals.Quote1SetupTotal;
    quoteTotals.Quote1SetupTotal = calculateDiscount(
      quoteDiscounts.Quote1DiscountTypeSetup,
      quoteDiscounts.Quote1DiscountSetup,
      quoteTotals.Quote1SetupTotal
    );
    quoteTotals.Quote2SetupSubTotal = quoteTotals.Quote2SetupTotal;
    quoteTotals.Quote2SetupTotal = calculateDiscount(
      quoteDiscounts.Quote2DiscountTypeSetup,
      quoteDiscounts.Quote2DiscountSetup,
      quoteTotals.Quote2SetupTotal
    );
    return quoteTotals;
  };

  const calculateQantasQuoteTotal = () => {
    let quoteTotals = {
      Quote1HardwareTotal: 0,
      Quote2HardwareTotal: 0,
      Quote1MonthlyTotal: 0,
      Quote2MonthlyTotal: 0,
      Quote1SetupTotal: 0,
      Quote2SetupTotal: 0,
    };
    quoteItems.forEach((item) => {
      if (item.Price1QantasPointsApplicable === 1)
        quoteTotals[`Quote1${capitalizeStr(item.PriceType)}Total`] += parseInt(
          item.Price1 ? item.Price1 : 0
        );
      if (item.Price2QantasPointsApplicable === 1)
        quoteTotals[`Quote2${capitalizeStr(item.PriceType)}Total`] += parseInt(
          item.Price2 ? item.Price2 : 0
        );
    });

    // monthly
    quoteTotals.Quote1MonthlyTotal =
      quoteTotals.Quote1MonthlyTotal > 0
        ? calculateDiscount(
            quoteDiscounts.Quote1DiscountTypeMonthly,
            quoteDiscounts.Quote1DiscountMonthly,
            quoteTotals.Quote1MonthlyTotal
          )
        : 0;
    quoteTotals.Quote2MonthlyTotal =
      quoteTotals.Quote2MonthlyTotal > 0
        ? calculateDiscount(
            quoteDiscounts.Quote2DiscountTypeMonthly,
            quoteDiscounts.Quote2DiscountMonthly,
            quoteTotals.Quote2MonthlyTotal
          )
        : 0;

    // hardware
    quoteTotals.Quote1HardwareTotal =
      quoteTotals.Quote1HardwareTotal > 0
        ? calculateDiscount(
            quoteDiscounts.Quote1DiscountTypeHardware,
            quoteDiscounts.Quote1DiscountHardware,
            quoteTotals.Quote1HardwareTotal
          )
        : 0;
    quoteTotals.Quote2HardwareTotal =
      quoteTotals.Quote2HardwareTotal > 0
        ? calculateDiscount(
            quoteDiscounts.Quote2DiscountTypeHardware,
            quoteDiscounts.Quote2DiscountHardware,
            quoteTotals.Quote2HardwareTotal
          )
        : 0;

    // setup
    quoteTotals.Quote1SetupTotal =
      quoteTotals.Quote1SetupTotal > 0
        ? calculateDiscount(
            quoteDiscounts.Quote1DiscountTypeSetup,
            quoteDiscounts.Quote1DiscountSetup,
            quoteTotals.Quote1SetupTotal
          )
        : 0;
    quoteTotals.Quote2SetupTotal =
      quoteTotals.Quote2SetupTotal > 0
        ? calculateDiscount(
            quoteDiscounts.Quote2DiscountTypeSetup,
            quoteDiscounts.Quote2DiscountSetup,
            quoteTotals.Quote2SetupTotal
          )
        : 0;

    return quoteTotals;
  };

  const calculateDiscount = (type, discount, total) => {
    return type === 'DOLLAR'
      ? total - discount
      : total - total * (discount / 100);
  };

  const calculateQuoteTotalCosts = () => {
    let totalCosts = {
      monthly: 0,
      hardware: 0,
      setup: 0,
    };
    quoteItems.forEach((item) => {
      totalCosts[item.PriceType] += parseFloat(
        item.Price0 ? item.Price0 * item.Qty : 0
      );
    });
    return totalCosts;
  };

  const calculateTotalMargin = (price, cost) => {
    const marginNumber = parseFloat(price - cost).toFixed(2);
    let marginPercentage = calculateTotalMarginPercentage(price, cost);
    return `$${marginNumber} (${marginPercentage}%)`;
  };

  const calculateTotalMarginPercentage = (price, cost) => {
    let marginPercentage = ((price - cost) / price) * 100;
    if (Math.round(marginPercentage) === 0) {
      marginPercentage = parseFloat(marginPercentage).toFixed(2);
    } else {
      marginPercentage = Math.round(marginPercentage);
    }
    if (marginPercentage > 0 && marginPercentage !== Infinity) {
      marginPercentage = marginPercentage;
    } else {
      marginPercentage = 0;
    }

    return marginPercentage;
  };

  const calculateMarginPercentage = () => {
    const totalCosts = calculateQuoteTotalCosts();
    const quote1MonthlyTotal = calculateQuoteTotal().Quote1MonthlyTotal;
    const quote2MonthlyTotal = calculateQuoteTotal().Quote2MonthlyTotal;
    const quote1HardwareTotal = calculateQuoteTotal().Quote1HardwareTotal;
    const quote2HardwareTotal = calculateQuoteTotal().Quote2HardwareTotal;
    const quote1SetupTotal = calculateQuoteTotal().Quote1SetupTotal;
    const quote2SetupTotal = calculateQuoteTotal().Quote2SetupTotal;
    const marginPercentages = [
      {
        priceType: 'Monthly',
        quoteTypeId: 1,
        quoteType: data.Quote.QuoteType1.Name,
        value:
          quote1MonthlyTotal > 0
            ? calculateTotalMarginPercentage(
                quote1MonthlyTotal,
                totalCosts.monthly
              )
            : 0,
      },
      {
        priceType: 'Monthly',
        quoteTypeId: 2,
        quoteType: data.Quote.QuoteType2.Name,
        value:
          quote2MonthlyTotal > 0
            ? calculateTotalMarginPercentage(
                quote2MonthlyTotal,
                totalCosts.monthly
              )
            : 0,
      },
      {
        priceType: 'Hardware',
        quoteTypeId: 1,
        quoteType: data.Quote.QuoteType1.Name,
        value:
          quote1HardwareTotal > 0
            ? calculateTotalMarginPercentage(
                quote1HardwareTotal,
                totalCosts.hardware
              )
            : 0,
      },
      {
        priceType: 'Hardware',
        quoteTypeId: 2,
        quoteType: data.Quote.QuoteType2.Name,
        value:
          quote2HardwareTotal > 0
            ? calculateTotalMarginPercentage(
                quote2HardwareTotal,
                totalCosts.hardware
              )
            : 0,
      },
      {
        priceType: 'Setup',
        quoteTypeId: 1,
        quoteType: data.Quote.QuoteType1.Name,
        value:
          quote1SetupTotal > 0
            ? calculateTotalMarginPercentage(quote1SetupTotal, totalCosts.setup)
            : 0,
      },
      {
        priceType: 'Setup',
        quoteTypeId: 2,
        quoteType: data.Quote.QuoteType2.Name,
        value:
          quote2SetupTotal > 0
            ? calculateTotalMarginPercentage(quote2SetupTotal, totalCosts.setup)
            : 0,
      },
      {
        priceType: 'Up-Front',
        quoteTypeId: 1,
        quoteType: data.Quote.QuoteType1.Name,
        value:
          quote1HardwareTotal + quote1SetupTotal > 0
            ? calculateTotalMarginPercentage(
                quote1HardwareTotal + quote1SetupTotal,
                totalCosts.hardware + totalCosts.setup
              )
            : 0,
      },
      {
        priceType: 'Up-Front',
        quoteTypeId: 2,
        quoteType: data.Quote.QuoteType2.Name,
        value:
          quote2HardwareTotal + quote2SetupTotal > 0
            ? calculateTotalMarginPercentage(
                quote2HardwareTotal + quote2SetupTotal,
                totalCosts.hardware + totalCosts.setup
              )
            : 0,
      },
    ];
    return marginPercentages;
  };

  /**
   * Add new quote item when user select a product with product prices based on the quote quote types
   */
  const selectProduct = (prod) => {
    setIsSetCreatedQuoteItems(false);
    setSelectedProduct(prod);
    // if selected product has pricing add an quote item entry
    if (prod.prices.length > 0) {
      addItemsToQuote([prod]);
    } else {
      // if bundle and has product
      if (prod.items && prod.items.length > 0) {
        const bundleItems = prod.items;
        let pricingCount = 0;
        const products = bundleItems
          .sort((a, b) => a.isLinkedToGroup - b.isLinkedToGroup)
          .map((item) => {
            let itemProduct = {
              label: item.Product.Name,
              value: item.Product.ProductId,
              prices: item.Product.ProductPrices,
              docs: item.Product.ProductDocuments,
              templates: item.Product.ProductTemplates,
              qty: item.Quantity,
              isLinkedToGroup: item.isLinkedToGroup,
            };
            if (itemProduct.prices.length > 0) pricingCount += 1;
            return itemProduct;
          });

        if (pricingCount > 0) {
          addItemsToQuote(products);
        } else {
          setStepError({
            error: true,
            message:
              "The selected product or bundle doesn't have any pricing specified and cannot be added to the quote",
          });
        }
      } else {
        setStepError({
          error: true,
          message:
            "The selected product or bundle doesn't have any pricing specified and cannot be added to the quote",
        });
      }
    }
    setSelectedProduct(null);
  };

  const addItemsToQuote = (products) => {
    const newArr = JSON.parse(JSON.stringify(quoteItems));

    //generates a unique ID for the product
    var uniqueid = generateUniqueID();

    products.forEach(function (prod, i) {
      //regenerate the unique id if the product is not included in the group
      if (!prod.isLinkedToGroup) {
        uniqueid = generateUniqueID();
      }

      if (prod.prices.length > 0) {
        const productPriceTypes = [
          ...new Set(prod.prices.map((price) => price.PriceType)),
        ];
        // deep clone quote items state
        let quoteType1 = parseInt(data.Quote.QuoteType1.QuoteTypeId);
        let quoteType2 = parseInt(data.Quote.QuoteType2.QuoteTypeId);

        for (const priceType of productPriceTypes) {
          const productPrice0 = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 0
            )
            .map((price) => price.Price)[0];
          let productPrice1 = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType &&
                price.QuoteTypeId === data.Quote.QuoteTypeId1
            )
            .map((price) => price.Price)[0];
          let productPrice2 = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType &&
                price.QuoteTypeId === data.Quote.QuoteTypeId2
            )
            .map((price) => price.Price)[0];
          let prodDesc = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType &&
                price.QuoteTypeId === data.Quote.QuoteTypeId1
            )
            .map((price) => price.Description)[0];
          let prodDescAlt = prod.prices
            .filter((price) => price.PriceType === priceType)
            .map((price) => price.Description)[0];
          let isDisplayedCapexPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 1
            )
            .map((price) => price.isDisplayedPrice)[0];
          let isDisplayedOpexPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 2
            )
            .map((price) => price.isDisplayedPrice)[0];

          let displayProductCapexPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 1
            )
            .map((price) => price.DisplayProduct)[0];
          let displayProductOpexPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 2
            )
            .map((price) => price.DisplayProduct)[0];
          let displayProductQantasPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 3
            )
            .map((price) => price.DisplayProduct)[0];
          let displayProductEnerdsPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 4
            )
            .map((price) => price.DisplayProduct)[0];

          let includePriceInTotalCapexPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 1
            )
            .map((price) => price.IncludePriceInTotal)[0];
          let includePriceInTotalOpexPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 2
            )
            .map((price) => price.IncludePriceInTotal)[0];
          let includePriceInTotalQantasPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 3
            )
            .map((price) => price.IncludePriceInTotal)[0];
          let includePriceInTotalEnerdsPrice = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType && price.QuoteTypeId === 4
            )
            .map((price) => price.IncludePriceInTotal)[0];

          let displayProduct1 = null;
          let displayProduct2 = null;
          let includePriceInTotal1 = null;
          let includePriceInTotal2 = null;

          switch (quoteType1) {
            case 1:
              displayProduct1 = displayProductCapexPrice;
              includePriceInTotal1 = includePriceInTotalCapexPrice;
              break;
            case 2:
              displayProduct1 = displayProductOpexPrice;
              includePriceInTotal1 = includePriceInTotalOpexPrice;
              break;
            case 3:
              displayProduct1 = displayProductQantasPrice;
              includePriceInTotal1 = includePriceInTotalQantasPrice;
              break;
            case 4:
              displayProduct1 = displayProductEnerdsPrice;
              includePriceInTotal1 = includePriceInTotalEnerdsPrice;
              break;
          }

          switch (quoteType2) {
            case 1:
              displayProduct2 = displayProductCapexPrice;
              includePriceInTotal2 = includePriceInTotalCapexPrice;
              break;
            case 2:
              displayProduct2 = displayProductOpexPrice;
              includePriceInTotal2 = includePriceInTotalOpexPrice;
              break;
            case 3:
              displayProduct2 = displayProductQantasPrice;
              includePriceInTotal2 = includePriceInTotalQantasPrice;
              break;
            case 4:
              displayProduct2 = displayProductEnerdsPrice;
              includePriceInTotal2 = includePriceInTotalEnerdsPrice;
              break;
          }

          let tests = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType &&
                price.QuoteTypeId === quoteType1
            )
            .map((price) => price.DisplayProduct)[0];
          let test = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType &&
                price.QuoteTypeId === quoteType2
            )
            .map((price) => price.IncludePriceInTotal)[0];

          if (prodDesc === undefined) {
            prodDesc = prodDescAlt;
          }

          const price1QantasPointsApplicable = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType &&
                price.QuoteTypeId === data.Quote.QuoteTypeId1
            )
            .map((price) => price.QantasPointsApplicable)[0];
          const price2QantasPointsApplicable = prod.prices
            .filter(
              (price) =>
                price.PriceType === priceType &&
                price.QuoteTypeId === data.Quote.QuoteTypeId2
            )
            .map((price) => price.QantasPointsApplicable)[0];

          let itemsInType = newArr.filter(
            (item) => item.PriceType === priceType
          );
          let quoteItem = {
            ProductId: prod.value,
            QuoteId: props.pathArg,
            Price0: productPrice0 ? productPrice0 : 0,
            Price1: productPrice1 ? productPrice1 : 0,
            Price2: productPrice2 ? productPrice2 : 0,
            PriceType: priceType,
            Description: prodDesc,
            Qty: prod.qty ? prod.qty : 1,
            ItemGroupId: uniqueid,
            ComparisonLineItems: null,
            ComparisonPrice: null,
            SiteTag: null,
            Order:
              itemsInType.length > 0
                ? itemsInType[itemsInType.length - 1].Order + 1
                : 1,
            Price1QantasPointsApplicable: price1QantasPointsApplicable,
            Price2QantasPointsApplicable: price2QantasPointsApplicable,
            Modification: 0,
            ShowPriceQuote1: isDisplayedCapexPrice ? isDisplayedCapexPrice : 0,
            ShowPriceQuote2: isDisplayedOpexPrice ? isDisplayedOpexPrice : 0,
            DisplayProductQuote1: displayProduct1 ? displayProduct1 : 0,
            DisplayProductQuote2: displayProduct2 ? displayProduct2 : 0,
            IncludePriceInTotalQuote1: includePriceInTotal1
              ? includePriceInTotal1
              : 0,
            IncludePriceInTotalQuote2: includePriceInTotal2
              ? includePriceInTotal2
              : 0,
            Product: {
              Name: prod.label,
              ProductDocuments: prod.docs,
              ProductTemplates: prod.templates,
            },
          };
          newArr.push(quoteItem);
        }
      }

      //make sure the next product will have a new unique ID (this is required)
      if (!prod.isLinkedToGroup) {
        uniqueid = generateUniqueID();
      }
    });

    createQuoteItems({
      variables: {
        QuoteItemsList: JSON.parse(JSON.stringify(newArr)).map((item) => {
          item.QuoteId = parseInt(item.QuoteId, 10);
          delete item.Product;
          return item;
        }),
      },
    });
  };

  /**
   * Update the quoteItem state when user update the fields of the quote items
   */
  const updateQuoteItemEntry = (e, field, lineId) => {
    const index = quoteItems.map((line) => line.LineId).indexOf(lineId);
    const newItem = Object.assign({}, quoteItems[index]);

    if (
      field === 'Modification' ||
      field === 'ShowPriceQuote1' ||
      field === 'ShowPriceQuote2' ||
      field === 'DisplayProductQuote1' ||
      field === 'DisplayProductQuote2' ||
      field === 'IncludePriceInTotalQuote1' ||
      field === 'IncludePriceInTotalQuote2'
    )
      newItem[field] = e;
    else newItem[field] = e.target ? e.target.value : e.value; // check if field value is from input or dropdown
    const newArr = [...quoteItems];
    newItem.TemplateId = parseInt(newItem.TemplateId, 10);
    newItem.Price1 = parseFloat(newItem.Price1);
    newItem.Price2 = parseFloat(newItem.Price2);
    newItem.Qty = parseInt(newItem.Qty, 10);
    newArr[index] = newItem;
    updateQuoteItem({
      variables: newItem,
    });
    setQuoteItems(newArr);
  };

  /**
   * delete the quote item entry, also in the DB
   */
  const deleteQuoteItemEntry = (e, lineId) => {
    e.preventDefault();
    deleteQuoteItem({
      variables: {
        LineId: lineId,
      },
    });
    const index = quoteItems.map((line) => line.LineId).indexOf(lineId);
    const newArr = [...quoteItems];
    newArr.splice(index, 1);
    setQuoteItems(newArr);
  };

  const goToPreviousStep = (e) => {
    setPrevious(true);
    e.preventDefault();
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: data.Quote.QuoteId,
          HubSpotDealId: data.Quote.HubSpotDealId,
          WorkflowStep: 'QUOTE_SETUP',
        },
      },
    });
  };

  const proceedNextStep = (e) => {
    e.preventDefault();
    setStepError({
      error: true,
      message: `Please enter a quote item entry to the Quote: ${props.pathArg}, before you can proceed to next step`,
    });
    if (quoteItems && quoteItems.length === 0) {
      setStepError({
        error: true,
        message: `Please enter a quote item entry to the Quote: ${props.pathArg}, before you can proceed to next step`,
      });
      setProceed(false);
    } else {
      checkMinMarginPercentage();
    }
  };

  const checkMinMarginPercentage = () => {
    // check min margin percentage
    let minMarginPercentageErrors = [];
    const marginPercentages = calculateMarginPercentage();

    marginPercentages.forEach((marginPercentage) => {
      if (marginPercentage.value < env.MINIMUM_QUOTE_MARGIN) {
        minMarginPercentageErrors.push({
          priceType: marginPercentage.priceType,
          quoteType: marginPercentage.quoteType,
          quoteTypeId: marginPercentage.quoteTypeId,
          value: marginPercentage.value,
        });
      }
    });
    const checkNoZapEvents = checkNoZap();
    if (minMarginPercentageErrors.length > 0) {
      minMarginPercentageErrors.forEach((error) => {
        if (
          error.priceType === 'Monthly' ||
          error.priceType === 'Hardware' ||
          error.priceType === 'Setup'
        ) {
          setStepError({
            error: true,
            marginError: true,
            message: `The ${error.priceType} Margin on Quote ${error.quoteTypeId} is below minimum margin of ${env.MINIMUM_QUOTE_MARGIN}%.`,
          });
          setProceed(false);
        } else {
          setStepError({
            error: true,
            marginError: true,
            message: `The ${error.priceType} cost of Quote ${error.quoteTypeId} (${error.quoteType}) is below minimum margin of ${env.MINIMUM_QUOTE_MARGIN}%.`,
          });
          setProceed(false);
        }
      });
    } else {
      if (checkNoZapEvents === '') updateQuoteStep();
      else {
        setStepError({
          error: true,
          zapEventsError: true,
          message: `${checkNoZap()}`,
        });
        setProceed(false);
      }
    }
  };

  const checkNoZap = () => {
    const itemsWithZapEvents = quoteItems.filter(
      (item) => item.Product.ZapierQuoted || item.Product.ZapierOrdered
    );
    return itemsWithZapEvents.length === 0
      ? "There are no provisioning Zap's associated with any of the products in this quote.  Please add the relevant core products, or notify the PM team manually before proceeding."
      : '';
  };

  const updateQuoteStep = () => {
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: data.Quote.QuoteId,
          HubSpotDealId: data.Quote.HubSpotDealId,
          WorkflowStep: 'QUOTE_RATES',
          SummariseAllHardwareItemsQuote1:
            SummariseAllHardwareItemsQuote1 !== null
              ? SummariseAllHardwareItemsQuote1
              : data.Quote.SummariseAllHardwareItemsQuote1,
          SummariseAllHardwareItemsQuote2:
            SummariseAllHardwareItemsQuote2 !== null
              ? SummariseAllHardwareItemsQuote2
              : data.Quote.SummariseAllHardwareItemsQuote2,
        },
      },
    });
    setStepError({ error: false, message: '', marginError: false });
    setProceed(true);
  };

  const updateStaticProposalHTML = () => {
    const updatedQuote = updatedQuoteData.updateQuote;
    generateStaticProposalHTML({
      variables: {
        QuoteId: parseInt(updatedQuote.QuoteId, 10),
        Token: updatedQuote.Token,
      },
    });
  };

  const addQuoteRates = (e) => {
    setIsCreatedQuoteRates(false);
    let quoteRates = [];
    // get the first product rates for each quote item
    quoteItems.forEach((item) => {
      item.Product.ProductRates.map((rate) => {
        if (
          quoteRates.map((qr) => qr.RateId).indexOf(rate.RateId) === -1 &&
          quoteRates.filter(
            (qr) =>
              qr.RateType === rate.RateType &&
              qr.QuoteTypeId === rate.QuoteTypeId
          ).length === 0
        ) {
          quoteRates.push(rate);
        }
        return null;
      });
    });

    const rateTypes = [...new Set(quoteRates.map((qr) => qr.RateType))];
    const quoteRatesList = [];
    for (const rateType of rateTypes) {
      const quote1Rate = quoteRates
        .filter(
          (qr) =>
            qr.RateType === rateType &&
            qr.QuoteTypeId === data.Quote.QuoteTypeId1
        )
        .map((qr) => qr.Rate)[0];
      const quote2Rate = quoteRates
        .filter(
          (qr) =>
            qr.RateType === rateType &&
            qr.QuoteTypeId === data.Quote.QuoteTypeId2
        )
        .map((qr) => qr.Rate)[0];
      const rateUnits = quoteRates
        .filter(
          (qr) =>
            qr.RateType === rateType &&
            qr.QuoteTypeId === data.Quote.QuoteTypeId2
        )
        .map((qr) => qr.RateUnits)[0];

      quoteRatesList.push({
        QuoteId: parseInt(props.pathArg),
        RateType: rateType,
        RateUnits: rateUnits,
        Quote1Rate: quote1Rate,
        Quote2Rate: quote2Rate,
        Order: quoteRatesList.length + 1,
      });
    }

    createQuoteRates({
      variables: {
        QuoteRatesList: quoteRatesList,
      },
    });
    if (proposalInserts.length) {
      setUpdateProposal(true);
    }
  };

  const onDragEnd = (result) => {
    const { draggableId, source, destination } = result;

    // dropped outside the tables
    if (!destination) {
      return;
    }

    const LineId = draggableId;
    const prevOrder = source.index;
    const newOrder = destination ? destination.index : prevOrder;
    const prevSection = source.droppableId;
    const newSection = destination.droppableId;
    const quoteItemsCopy = JSON.parse(JSON.stringify(quoteItems));
    const movedItem = quoteItems.filter(
      (item) => item.LineId === draggableId
    )[0];

    // same section
    if (prevSection === newSection) {
      let reorderItems = quoteItemsCopy;
      reorderItems.filter((item) => item.LineId === LineId)[0].Order = newOrder;
      reorderItems = reorderItems.map((item) => {
        if (item.LineId !== LineId) {
          // upward
          if (prevOrder > newOrder) {
            if (item.Order >= newOrder) {
              item.Order += 1;
            }
          }
          // downward
          else if (prevOrder < newOrder) {
            if (newSection === item.PriceType) {
              if (item.Order > prevOrder && item.Order <= newOrder) {
                item.Order -= 1;
              }
            }
          }
        }
        return item;
      });
      setQuoteItems(reorderItems);
      updateQuoteItems({
        variables: {
          QuoteItemsList: JSON.parse(JSON.stringify(reorderItems)).map(
            (item) => {
              delete item.Product;
              return item;
            }
          ),
        },
      });
    } else {
      const index = quoteItems
        .map((line) => line.LineId)
        .indexOf(movedItem.LineId);
      const newItem = Object.assign({}, quoteItems[index]);
      newItem.PriceType = newSection;
      newItem.Order = newOrder;
      let newArr = JSON.parse(JSON.stringify(quoteItems));
      newArr[index] = newItem;
      newArr = newArr.map((item) => {
        if (item.LineId !== LineId) {
          if (item.Order >= newOrder) {
            item.Order += 1;
          }
        }
        return item;
      });
      setQuoteItems(newArr);
      updateQuoteItems({
        variables: {
          QuoteItemsList: JSON.parse(JSON.stringify(newArr)).map((item) => {
            delete item.Product;
            return item;
          }),
        },
      });
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? '#eee' : '#fff',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  /**
   * Render the tables with quote items based on the price type
   */
  const renderTables = (priceType, i) => {
    return (
      <Droppable key={i} droppableId={priceType}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className="table-responsive"
            key={priceType}
          >
            <h6
              style={{
                textTransform: 'capitalize',
                marginTop: i === 0 ? '0px' : '20px',
              }}
            >
              {priceType}
            </h6>
            {data && data.Quote && quoteItems && (
              <table className="table mb-0" key={i}>
                <thead className="table-dark">
                  <tr>
                    {QUOTE_ITEMS_COLUMN_HEADERS.map((column) => (
                      <ColumnHeadersTooltip
                        key={column.id}
                        id={'product-pricing'}
                        name={priceType}
                        column={column}
                        quoteType1={data.Quote.QuoteType1.Name}
                        quoteType2={data.Quote.QuoteType2.Name}
                      />
                    ))}
                    <th style={{ width: '40px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {data.Quote &&
                  quoteItems &&
                  quoteItems.filter((quote) => quote.PriceType === priceType)
                    .length > 0 ? (
                    quoteItems.sort((a, b) => (a.Order > b.Order ? 1 : -1)) &&
                    quoteItems
                      .filter((quote) => quote.PriceType === priceType)
                      .map((item, index) => (
                        <Draggable
                          key={item.LineId}
                          draggableId={item.LineId}
                          index={item.Order}
                        >
                          {(provided, snapshot) => (
                            <tr
                              key={item.LineId}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <td style={{ padding: '5px' }}>
                                <div>
                                  <i
                                    className="fas fa-ellipsis-v"
                                    style={{ color: '#bbb' }}
                                  ></i>
                                  <i
                                    className="fas fa-ellipsis-v"
                                    style={{ color: '#bbb' }}
                                  ></i>
                                </div>
                                <div
                                  style={{
                                    width: '130px',
                                    position: 'relative',
                                    top: '-22px',
                                    left: '14px',
                                    paddingRight: ' 5px',
                                  }}
                                >
                                  {item.LineId + '-' + item.Description}
                                </div>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={item.Qty}
                                  onChange={(e) => {
                                    updateQuoteItemEntry(e, 'Qty', item.LineId);
                                    quoteItems.forEach((quote_item, index) => {
                                      if (
                                        quote_item.ItemGroupId &&
                                        quote_item.ItemGroupId ===
                                          item.ItemGroupId
                                      ) {
                                        quoteItems[index].Qty = parseInt( e.target.value, 10);
                                        updateQuoteItem({
                                          variables: quote_item,
                                        });
                                      }
                                    });
                                  }}
                                  className="form-control"
                                  style={{ width: '90px' }}
                                />
                              </td>
                              <td>
                                {item.ItemGroupId ? item.ItemGroupId : '-'}
                              </td>
                              <td>{item.ProductId}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  defaultChecked={item.Modification}
                                  onChange={(e) =>
                                    updateQuoteItemEntry(
                                      e.target.checked ? 1 : 0,
                                      'Modification',
                                      item.LineId
                                    )
                                  }
                                />
                              </td>
                              <td className="text-right">
                                ${parseFloat(item.Price0).toFixed(2)}
                                {item.Product.XeroTotalCostPool &&
                                  item.Product.XeroQuantityOnHand && (
                                    <TooltipItem
                                      key={`tooltip-${index}`}
                                      id={`xero-total-cost-pool-${item.LineId}`}
                                      label={``}
                                      description={(() => {
                                        let average_buy_price = '';
                                        if (
                                          item.Product.XeroTotalCostPool &&
                                          item.Product.XeroQuantityOnHand
                                        ) {
                                          average_buy_price = (
                                            parseFloat(
                                              item.Product.XeroTotalCostPool
                                            ) /
                                            parseFloat(
                                              item.Product.XeroQuantityOnHand
                                            )
                                          ).toFixed(2);
                                        }
                                        return (
                                          <div>
                                            The average cost price of this item
                                            recently is <br /> $
                                            {average_buy_price}
                                          </div>
                                        );
                                      })()}
                                    />
                                  )}
                              </td>
                              <td style={{ position: 'relative' }}>
                                <input
                                  style={{ minWidth: '100px' }}
                                  type="number"
                                  defaultValue={item.Price1}
                                  onChange={(e) =>
                                    updateQuoteItemEntry(
                                      e,
                                      'Price1',
                                      item.LineId
                                    )
                                  }
                                  className="form-control text-right"
                                />
                                <div className="line-total">
                                  <span>
                                    = $
                                    {parseFloat(item.Price1 * item.Qty).toFixed(
                                      2
                                    )}
                                  </span>
                                </div>
                                <div className="show-price-container">
                                  <input
                                    type="checkbox"
                                    defaultChecked={item.ShowPriceQuote1}
                                    onChange={(e) =>
                                      updateQuoteItemEntry(
                                        e.target.checked ? 1 : 0,
                                        'ShowPriceQuote1',
                                        item.LineId
                                      )
                                    }
                                  />
                                  <span className="show-price-text">
                                    Display Price
                                  </span>
                                </div>

                                <div className="display-product-container">
                                  <input
                                    type="checkbox"
                                    defaultChecked={item.DisplayProductQuote1}
                                    onChange={(e) =>
                                      updateQuoteItemEntry(
                                        e.target.checked ? 1 : 0,
                                        'DisplayProductQuote1',
                                        item.LineId
                                      )
                                    }
                                  />
                                  <span className="display-product-text">
                                    Display Product
                                  </span>
                                </div>
                                <div className="include-price-container">
                                  <input
                                    type="checkbox"
                                    defaultChecked={
                                      item.IncludePriceInTotalQuote1
                                    }
                                    onChange={(e) =>
                                      updateQuoteItemEntry(
                                        e.target.checked ? 1 : 0,
                                        'IncludePriceInTotalQuote1',
                                        item.LineId
                                      )
                                    }
                                  />
                                  <span className="include-price-text">
                                    Include in Total
                                  </span>
                                </div>
                              </td>
                              <td style={{ position: 'relative' }}>
                                <input
                                  style={{ minWidth: '100px' }}
                                  type="number"
                                  defaultValue={item.Price2}
                                  onChange={(e) =>
                                    updateQuoteItemEntry(
                                      e,
                                      'Price2',
                                      item.LineId
                                    )
                                  }
                                  className="form-control text-right"
                                />
                                <div className="line-total">
                                  <span>
                                    = $
                                    {parseFloat(item.Price2 * item.Qty).toFixed(
                                      2
                                    )}
                                  </span>
                                </div>
                                <div className="show-price-container">
                                  <input
                                    type="checkbox"
                                    defaultChecked={item.ShowPriceQuote2}
                                    onChange={(e) =>
                                      updateQuoteItemEntry(
                                        e.target.checked ? 1 : 0,
                                        'ShowPriceQuote2',
                                        item.LineId
                                      )
                                    }
                                  />
                                  <span className="show-price-text">
                                    Display Price
                                  </span>
                                </div>

                                <div className="display-product-container">
                                  <input
                                    type="checkbox"
                                    defaultChecked={item.DisplayProductQuote2}
                                    onChange={(e) =>
                                      updateQuoteItemEntry(
                                        e.target.checked ? 1 : 0,
                                        'DisplayProductQuote2',
                                        item.LineId
                                      )
                                    }
                                  />
                                  <span className="display-product-text">
                                    Display Product
                                  </span>
                                </div>
                                <div className="include-price-container">
                                  <input
                                    type="checkbox"
                                    defaultChecked={
                                      item.IncludePriceInTotalQuote2
                                    }
                                    onChange={(e) =>
                                      updateQuoteItemEntry(
                                        e.target.checked ? 1 : 0,
                                        'IncludePriceInTotalQuote2',
                                        item.LineId
                                      )
                                    }
                                  />
                                  <span className="include-price-text">
                                    Include in Total
                                  </span>
                                </div>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={item.ComparisonLineItems}
                                  className="form-control"
                                  onChange={(e) =>
                                    updateQuoteItemEntry(
                                      e,
                                      'ComparisonLineItems',
                                      item.LineId
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  defaultValue={item.ComparisonPrice}
                                  className="form-control"
                                  onChange={(e) =>
                                    updateQuoteItemEntry(
                                      e,
                                      'ComparisonPrice',
                                      item.LineId
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  style={{ width: '90px' }}
                                  type="text"
                                  value={item.SiteTag}
                                  className="form-control"
                                  onChange={(e) => {
                                    updateQuoteItemEntry(
                                      e,
                                      'SiteTag',
                                      item.LineId
                                    );
                                    quoteItems.forEach((quote_item, index) => {
                                      if (
                                        quote_item.ItemGroupId &&
                                        quote_item.ItemGroupId ===
                                          item.ItemGroupId
                                      ) {
                                        quoteItems[index].SiteTag =
                                          e.target.value;
                                        updateQuoteItem({
                                          variables: quote_item,
                                        });
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <div style={{ width: '120px' }}>
                                  <Select
                                    placeholder={'Select template'}
                                    options={[
                                      { value: null, label: 'None' },
                                      ...item.Product.ProductTemplates.map(
                                        (template) => ({
                                          value: template.ProductTemplateId,
                                          label: template.ProductTemplateName,
                                        })
                                      ),
                                    ]}
                                    onChange={(e) =>
                                      updateQuoteItemEntry(
                                        e,
                                        'TemplateId',
                                        item.LineId
                                      )
                                    }
                                    defaultValue={
                                      item.TemplateId
                                        ? {
                                            value: item.TemplateId,
                                            label:
                                              item.Product.ProductTemplates.filter(
                                                (template) =>
                                                  parseInt(
                                                    template.ProductTemplateId
                                                  ) ===
                                                  parseInt(item.TemplateId)
                                              ).map(
                                                (template) =>
                                                  template.ProductTemplateName
                                              ),
                                          }
                                        : { value: null, label: 'None' }
                                    }
                                    menuPortalTarget={document.querySelector(
                                      'body'
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) =>
                                    deleteQuoteItemEntry(e, item.LineId)
                                  }
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))
                  ) : (
                    <tr key={0}>
                      <td colSpan={10} className="text-center">
                        No quote item
                      </td>
                    </tr>
                  )}
                  <tr className="tr-subtotal">
                    <td colSpan={5}>
                      <span className="text-capitalize">{priceType}</span>{' '}
                      Subtotal <small>(SRP)</small>
                    </td>
                    <td className="text-right">
                      $
                      {parseFloat(
                        calculateQuoteTotalCosts()[priceType]
                      ).toFixed(2)}
                    </td>
                    <td className="text-right">
                      $
                      {parseFloat(
                        calculateQuoteTotal()[
                          `Quote1${capitalizeStr(priceType)}SubTotal`
                        ]
                      ).toFixed(2)}
                    </td>
                    <td className="text-right">
                      $
                      {parseFloat(
                        calculateQuoteTotal()[
                          `Quote2${capitalizeStr(priceType)}SubTotal`
                        ]
                      ).toFixed(2)}
                    </td>
                    <td colSpan={5}></td>
                  </tr>
                  {priceType.toLowerCase() === 'hardware' && (
                    <tr className="tr-discount text-right">
                      <td colSpan={6}>
                        <small className="my-5">
                          Summarise as "All Hardware Items"
                        </small>
                      </td>
                      <td>
                        <div className="d-flex align-items-center display-product-container">
                          <input
                            type="checkbox"
                            defaultChecked={
                              data &&
                              data.Quote &&
                              data.Quote.SummariseAllHardwareItemsQuote1
                            }
                            onChange={(e) => {
                              setSummariseAllHardwareItemsQuote1(
                                +e.target.checked
                              );
                            }}
                          />
                          <small>
                            <span className="ml-1">Quote 1</span>
                          </small>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center display-product-container">
                          <input
                            type="checkbox"
                            defaultChecked={
                              data &&
                              data.Quote &&
                              data.Quote.SummariseAllHardwareItemsQuote2
                            }
                            onChange={(e) => {
                              setSummariseAllHardwareItemsQuote2(
                                +e.target.checked
                              );
                            }}
                          />
                          <small>
                            <span className="ml-1">Quote 2</span>
                          </small>
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr className="tr-discount">
                    <td colSpan={6}>
                      <span className="text-capitalize">{priceType}</span>{' '}
                      Discount
                    </td>
                    <td className="text-right">
                      <div className="flex-container">
                        <select
                          className="form-control discount-type-select"
                          defaultValue={
                            data.Quote[
                              `Quote1DiscountType${capitalizeStr(priceType)}`
                            ]
                          }
                          onChange={(e) =>
                            onChangeQuoteDiscountTotal(
                              e,
                              `Quote1DiscountType${capitalizeStr(priceType)}`
                            )
                          }
                        >
                          <option value="DOLLAR">$</option>
                          <option value="PERCENT">%</option>
                        </select>
                        <input
                          type="number"
                          className="form-control discount-input text-right"
                          defaultValue={
                            data.Quote[
                              `Quote1Discount${capitalizeStr(priceType)}`
                            ]
                          }
                          onChange={(e) =>
                            onChangeQuoteDiscountTotal(
                              e,
                              `Quote1Discount${capitalizeStr(priceType)}`
                            )
                          }
                        />
                      </div>
                    </td>
                    <td className="text-right">
                      <div className="flex-container">
                        <select
                          className="form-control discount-type-select"
                          defaultValue={
                            data.Quote[
                              `Quote2DiscountType${capitalizeStr(priceType)}`
                            ]
                          }
                          onChange={(e) =>
                            onChangeQuoteDiscountTotal(
                              e,
                              `Quote2DiscountType${capitalizeStr(priceType)}`
                            )
                          }
                        >
                          <option value="DOLLAR">$</option>
                          <option value="PERCENT">%</option>
                        </select>
                        <input
                          type="number"
                          className="form-control discount-input text-right"
                          defaultValue={
                            data.Quote[
                              `Quote2Discount${capitalizeStr(priceType)}`
                            ]
                          }
                          onChange={(e) =>
                            onChangeQuoteDiscountTotal(
                              e,
                              `Quote2Discount${capitalizeStr(priceType)}`
                            )
                          }
                        />
                      </div>
                    </td>
                    <td colSpan={5}></td>
                  </tr>
                  <tr className="tr-total">
                    <td colSpan={6}>
                      <span className="text-capitalize">{priceType}</span> Total{' '}
                      <small>(After Discount)</small>
                    </td>
                    <td className="text-right">
                      $
                      {parseFloat(
                        calculateQuoteTotal()[
                          `Quote1${capitalizeStr(priceType)}Total`
                        ]
                      ).toFixed(2)}
                    </td>
                    <td className="text-right">
                      $
                      {parseFloat(
                        calculateQuoteTotal()[
                          `Quote2${capitalizeStr(priceType)}Total`
                        ]
                      ).toFixed(2)}
                    </td>
                    <td colSpan={5}></td>
                  </tr>

                  <tr className="tr-total-margin">
                    <td colSpan={6}>
                      <p className="text-capitalize">Total Margin</p>
                    </td>
                    <td className="text-right">
                      {calculateTotalMargin(
                        calculateQuoteTotal()[
                          `Quote1${capitalizeStr(priceType)}Total`
                        ],
                        calculateQuoteTotalCosts()[priceType]
                      )}
                    </td>
                    <td className="text-right">
                      {calculateTotalMargin(
                        calculateQuoteTotal()[
                          `Quote2${capitalizeStr(priceType)}Total`
                        ],
                        calculateQuoteTotalCosts()[priceType]
                      )}
                    </td>
                    <td colSpan={5}></td>
                  </tr>
                  {priceType === 'setup' &&
                    parseInt(env.QANTAS_POINTS_ENABLED) === 1 && (
                      <tr className="tr-qantas-bonus-points">
                        <td colSpan={6}>Bonus Qantas Points</td>
                        <td className="text-right">
                          {data.Quote.QuoteType1.QantasPointsApplicable ===
                          1 ? (
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={data.Quote.QuoteType1QantasBonus}
                              onChange={(e) =>
                                onChangeQantasBonusPoints(
                                  e,
                                  'QuoteType1QantasBonus'
                                )
                              }
                            />
                          ) : (
                            <span>N/A</span>
                          )}
                        </td>
                        <td className="text-right">
                          {data.Quote.QuoteType2.QantasPointsApplicable ===
                          1 ? (
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={data.Quote.QuoteType2QantasBonus}
                              onChange={(e) =>
                                onChangeQantasBonusPoints(
                                  e,
                                  'QuoteType2QantasBonus'
                                )
                              }
                            />
                          ) : (
                            <span>N/A</span>
                          )}
                        </td>
                        <td colSpan={5}></td>
                      </tr>
                    )}
                  {priceType === 'monthly' &&
                    parseInt(env.QANTAS_POINTS_ENABLED) === 1 && (
                      <tr className="tr-qantas-points">
                        <td colSpan={6}>Qantas Points</td>
                        <td className="text-right">
                          {parseInt(
                            calculateQantasQuoteTotal().Quote1MonthlyTotal
                          ) * env.QANTAS_POINTS_MULTIPLIER}
                        </td>
                        <td className="text-right">
                          {parseInt(
                            calculateQantasQuoteTotal().Quote2MonthlyTotal
                          ) * env.QANTAS_POINTS_MULTIPLIER}
                        </td>
                        <td colSpan={5}></td>
                      </tr>
                    )}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </div>
        )}
      </Droppable>
    );
  };

  return (
    <React.Fragment>
      {disableQuote && <div class="overlay"></div>}
      {stepError &&
        stepError.error === true &&
        (!stepError.marginError || stepError.marginError === undefined) && (
          <SweetAlert
            error
            title={<span style={{ fontSize: '24px' }}>Error</span>}
            onConfirm={(e) => setStepError({ error: false, message: '' })}
          >
            {stepError.message}
          </SweetAlert>
        )}

      {stepError &&
        stepError.error === true &&
        (stepError.marginError || stepError.zapEventsError) && (
          <SweetAlert
            error
            title={<span style={{ fontSize: '24px' }}>Error</span>}
            onConfirm={(e) => setStepError({ error: false, message: '' })}
            confirmBtnText={'Revise quote'}
            showCancel={true}
            onCancel={updateQuoteStep}
            cancelBtnText={'Ignore and continue'}
            cancelBtnCssClass={'btn-secondary'}
            cancelBtnStyle={{ color: '#fff', textDecoration: 'none' }}
            closeOnClickOutside={false}
          >
            {stepError.message}
          </SweetAlert>
        )}

      {stepError &&
        stepError.error === false &&
        proceed === true &&
        isQuoteUpdated === true && (
          <SweetAlert
            success
            title={<span style={{ fontSize: '24px' }}>Success</span>}
            onConfirm={(e) => addQuoteRates(e)}
            showConfirm={false}
            timeout={2000}
          >
            Quote items step complete. Proceeding to next step.
          </SweetAlert>
        )}
      {
        // load live search and quote item builder UI when quote with the ID passed on the URL exists
        data && data.Quote && !loading && (
          <div className="content" onScroll={console.log('scroll')}>
            <div className="container-fluid">
              <div id="wrapper">
                <div className="topbar product-search-bar">
                  <div className="navbar-custom product-search">
                    <h5
                      style={{ fontSize: '18px' }}
                    >{`$ ${data.Quote.HubSpotDealValue} - ${data.Quote.Description}  - Quote Items - JARVIS`}</h5>
                    {groupOptions && (
                      <Select
                        options={groupOptions}
                        onChange={selectProduct}
                        value={selectedProduct}
                        placeholder={'Select a product or bundle to add...'}
                      />
                    )}
                  </div>
                </div>
              </div>

              <Row style={{ marginTop: '160px' }}>
                <Col lg="12">
                  <Card className="card-content">
                    <CardBody>
                      <DragDropContext onDragEnd={onDragEnd}>
                        {PRICE_TYPES.map((priceType, i) =>
                          renderTables(priceType, i)
                        )}
                      </DragDropContext>
                      {data && quoteItems && (
                        <div className="mt-5">
                          <table
                            className="table mt-3"
                            id="table-upfront-totals"
                          >
                            <thead>
                              <tr>
                                <th style={{ width: '140px' }}>
                                  Up-Front Totals
                                </th>
                                <th style={{ width: '90px' }}></th>
                                <th style={{ width: '120px' }}></th>
                                <th style={{ width: '180px' }}>
                                  Quote 1 Price{' '}
                                  <span
                                    className={`badge badge-pill ${
                                      QUOTE_TYPES_BADGE_CLASSES[
                                        data.Quote.QuoteType1.Name
                                      ]
                                    } float-right`}
                                  >
                                    {data.Quote.QuoteType1.Name}
                                  </span>
                                </th>
                                <th style={{ width: '180px' }}>
                                  Quote 2 Price
                                  <span
                                    className={`badge badge-pill ${
                                      QUOTE_TYPES_BADGE_CLASSES[
                                        data.Quote.QuoteType2.Name
                                      ]
                                    } float-right`}
                                  >
                                    {data.Quote.QuoteType2.Name}
                                  </span>
                                </th>
                                <th style={{ width: '120px' }}></th>
                                <th style={{ width: '60px' }}></th>
                                <th style={{ width: '100px' }}></th>
                                <th style={{ width: '160px' }}></th>
                                <th style={{ width: '60px' }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={3}>GRAND TOTAL</td>
                                <td className="text-right">
                                  $
                                  {parseFloat(
                                    calculateQuoteTotal().Quote1HardwareTotal +
                                      calculateQuoteTotal().Quote1SetupTotal
                                  ).toFixed(2)}
                                </td>
                                <td className="text-right">
                                  $
                                  {parseFloat(
                                    calculateQuoteTotal().Quote2HardwareTotal +
                                      calculateQuoteTotal().Quote2SetupTotal
                                  ).toFixed(2)}
                                </td>
                                <td colSpan={5}></td>
                              </tr>
                              {parseInt(env.QANTAS_POINTS_ENABLED) === 1 && (
                                <tr>
                                  <td colSpan={3}>
                                    Up-Front Bonus Qantas Points
                                  </td>
                                  <td className="text-right">
                                    {qantasBonusPoints.QuoteType1QantasBonus}
                                  </td>
                                  <td className="text-right">
                                    {qantasBonusPoints.QuoteType2QantasBonus}
                                  </td>
                                  <td colSpan={5}></td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          <table
                            className="table mt-3"
                            id="table-monthly-totals"
                          >
                            <thead>
                              <tr>
                                <th style={{ width: '140px' }}>
                                  Monthly Totals
                                </th>
                                <th style={{ width: '90px' }}></th>
                                <th style={{ width: '120px' }}></th>
                                <th style={{ width: '180px' }}>
                                  Quote 1 Price{' '}
                                  <span
                                    className={`badge badge-pill ${
                                      QUOTE_TYPES_BADGE_CLASSES[
                                        data.Quote.QuoteType1.Name
                                      ]
                                    } float-right`}
                                  >
                                    {data.Quote.QuoteType1.Name}
                                  </span>
                                </th>
                                <th style={{ width: '180px' }}>
                                  Quote 2 Price
                                  <span
                                    className={`badge badge-pill ${
                                      QUOTE_TYPES_BADGE_CLASSES[
                                        data.Quote.QuoteType2.Name
                                      ]
                                    } float-right`}
                                  >
                                    {data.Quote.QuoteType2.Name}
                                  </span>
                                </th>
                                <th style={{ width: '120px' }}></th>
                                <th style={{ width: '60px' }}></th>
                                <th style={{ width: '100px' }}></th>
                                <th style={{ width: '160px' }}></th>
                                <th style={{ width: '60px' }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={3}>GRAND TOTAL</td>
                                <td className="text-right">
                                  $
                                  {parseFloat(
                                    calculateQuoteTotal().Quote1MonthlyTotal
                                  ).toFixed(2)}
                                </td>
                                <td className="text-right">
                                  $
                                  {parseFloat(
                                    calculateQuoteTotal().Quote2MonthlyTotal
                                  ).toFixed(2)}
                                </td>
                                <td colSpan={5}></td>
                              </tr>
                              {parseInt(env.QANTAS_POINTS_ENABLED) === 1 && (
                                <tr>
                                  <td colSpan={3}>Monthly Qantas Points</td>
                                  <td className="text-right">
                                    {calculateQantasQuoteTotal()
                                      .Quote1MonthlyTotal *
                                      env.QANTAS_POINTS_MULTIPLIER}
                                  </td>
                                  <td className="text-right">
                                    {calculateQantasQuoteTotal()
                                      .Quote2MonthlyTotal *
                                      env.QANTAS_POINTS_MULTIPLIER}
                                  </td>
                                  <td colSpan={5}></td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <button
                        className="btn-icon btn btn-light btn-lg float-left"
                        onClick={goToPreviousStep}
                      >
                        {' '}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-back mr-2"></i>
                        </span>{' '}
                        Previous Step
                      </button>
                      <button
                        className="btn-icon btn btn-primary btn-lg float-right"
                        onClick={proceedNextStep}
                      >
                        {' '}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-forward mr-2"></i>
                        </span>{' '}
                        Next Step
                      </button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
      {
        // when data is loaded and quote id is not valid show quote does not exists error UI
        data && data.Quote === null && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">Error loading this page</h4>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Quote with id {props.pathArg} does not exist.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(QuoteBuilderItems);
